.overflow-to-select-active-branch {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1052;
  display: block;
  background: rgba(255, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}
.overflow-to-select-active-branch .selector-box {
  background: white;
  width: 300px;
  min-height: 200px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overflow-to-select-active-branch .selector-box h5 {
  font-size: 18px;
  margin-bottom: 20px;
}
.overflow-to-select-active-branch .selector-box select {
min-width: 200px;
padding: 10px;
border-radius: 5px;
}
